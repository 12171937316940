<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div class="search-form btn-2b5a95">
      <div
        class="l-w"
        :style="{
          height: $store.state.global.isSearchExtend_1
            ? ''
            : $store.state.global.searchFormHeight_1 + 10 + 'px',
        }"
      >
        <el-form
          label-position="right"
          :class="
            $store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'
          "
					:model="searchForm"
					@submit.native.prevent
        >
          <el-form-item label="姓名:">
            <el-input
							v-model.trim="searchForm.studentname"
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="身份证:">
            <el-input
							v-model.trim="searchForm.studentidcard"
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="院校:">
            <el-input
							v-model.trim="searchForm.collegename"
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="专业:">
            <el-input
							v-model.trim="searchForm.majorname"
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="年级:">
            <el-input
							v-model.trim="searchForm.grade"
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="层次:">
            <el-input
							v-model.trim="searchForm.levelval"
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="班主任:">
            <el-input
							v-model.trim="searchForm.followusername"
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="教学点:">
            <el-input
							v-model.trim="searchForm.schoolorgname"
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="项目类型:">
            <el-select
							v-model="searchForm.projecttype"
							@visible-change="visibleType"
							@keyup.enter.native="seach"
							ref="projecttyperef"
							clearable>
              <el-option
                v-for="item in educationList"
                :key="item.key"
                :value="item.val"
                :label="item.val"
              ></el-option>
            </el-select>
          </el-form-item>
					<el-form-item label="备注:">
					  <el-input
							v-model.trim="searchForm.remark"
							@keyup.enter.native="seach"
							clearable />
					</el-form-item>

        </el-form>
      </div>
      <div class="r-w">
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="seach"
          :loading="!$store.state.global.isEndRequest"
          class="btn-2b5a95"
          >搜索</el-button
        >
				<el-button type="primary" @click="resetQuery">重置</el-button>
        <el-button
          type="primary"
          :icon="
            $store.state.global.isSearchExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          v-if="$store.state.global.searchExtend_1"
          class="btn-2b5a95"
          @click="onExtendClick('isSearchExtend_1')"
          >{{ $store.state.global.searchExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
			stripe
			border
			:header-cell-style="{ background: '#044d8c', color: '#fff'}"
      :data="tableRes.list"
      ref="multipleTable"
      style="width: 100%"
      :cell-style="{ padding: 0 }"
      @selection-change="handleSelectionChange"
			height="700px"
			fixed
    >
      <el-table-column type="selection" width="55" />
      <el-table-column label="姓名/身份证" align="center" width="200">
        <template slot-scope="scope">
          <el-button type="text" @click="btnShowDetail(scope.row.id)">
					{{
            scope.row.studentname
          }}
					</el-button>
					<br>
					{{
					  scope.row.studentidcard
					}}
        </template>
      </el-table-column>
      <el-table-column
        label="院校/专业/年级/层次/学习方式"
        align="center"
        width="320"
      >
				<template slot-scope="scope">
					{{scope.row.collegename}}/{{scope.row.majorname}}
					<br>
					{{scope.row.grade}}/ {{scope.row.levelval}}/ {{scope.row.studytypeval}}
				</template>
			</el-table-column>
      <el-table-column label="上课地点/挂靠函授站" align="center" width="160px">
				<template slot-scope="scope">
					{{scope.row.classaddress}}<br>
					{{scope.row.guakaoschoolorgname}}
				</template>
			</el-table-column>
			<el-table-column label="交费情况" align="center">
				<el-table-column label="应/实1" align="center" width="130px">
					<template slot-scope="scope">
						<p class="table_colim">{{scope.row.receivable1}}</p>
						<p>{{scope.row.recvmoney1}}</p>
					</template>
				</el-table-column>
				<el-table-column label="应/实2" align="center" width="130px">
					<template slot-scope="scope">
						<p class="table_colim">{{scope.row.receivable2}}</p>
						<p>{{scope.row.recvmoney2}}</p>
					</template>
				</el-table-column>
				<el-table-column label="应/实3" align="center" width="130px">
					<template slot-scope="scope">
						<p class="table_colim">{{scope.row.receivable3}}</p>
						<p>{{scope.row.recvmoney3}}</p>
					</template>
				</el-table-column>
				<el-table-column label="应/实4" align="center" width="130px">
					<template slot-scope="scope">
						<p class="table_colim">{{scope.row.receivable4}}</p>
						<p>{{scope.row.recvmoney4}}</p>
					</template>
				</el-table-column>
				<el-table-column label="应/实5" align="center" width="130px">
					<template slot-scope="scope">
						<p class="table_colim">{{scope.row.receivable5}}</p>
						<p>{{scope.row.recvmoney5}}</p>
					</template>
				</el-table-column>
			</el-table-column>
      <el-table-column label="班主任/项目类型" align="center" width="150px">
				<template slot-scope="scope">
					{{scope.row.followusername}}<br>
					{{scope.row.projecttype}}
				</template>
			</el-table-column>

			<el-table-column label="备注" prop="remark" align="center" :show-overflow-tooltip="true"/>
      <el-table-column label="教学点" prop="schoolorgname" align="center" width="100px"/>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!--添加，修改-->
    <el-dialog
      class="btn-2b5a95"
			v-dialogDrag
      width="1100px"
			:close-on-click-modal='false'
      :title="dialogTitle"
      :visible.sync="showAddModal"
      v-if="showAddModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="170px"
        class="demo-dynamic form-item-w-50"
        status-icon
        :rules="rules"
      >
        <el-form-item prop="companyorgname" label="所属分公司">
          <el-select
            v-model="dynamicValidateForm.companyorgname"
            placeholder="请选择"
            :disabled="!$store.state.login.isMaxAuth"
            clearable
            @change="onChangeCompany($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgname" label="所属教学点">
          <el-select
            v-model="dynamicValidateForm.schoolorgname"
            placeholder="请选择"
            clearable
            @change="onChangeSchool($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
							clearable
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="姓名:" prop="studentname">
          <el-input v-model="dynamicValidateForm.studentname" disabled />
        </el-form-item>
        <el-form-item label="入学年级:" prop="grade">
          <el-select v-model="dynamicValidateForm.grade" clearable>
            <el-option
              v-for="item in grade"
              :key="item.id"
              :value="item.key"
              :label="item.val"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="层次:" prop="levelkey">
          <el-select v-model="dynamicValidateForm.levelkey" clearable>
            <el-option
              v-for="item in leveltype"
              :key="item.key"
              :value="item.key"
              :label="item.val"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学习形式:" prop="studytypekey">
          <el-select v-model="dynamicValidateForm.studytypekey" clearable>
            <el-option
              v-for="item in studytypeval"
              :key="item.key"
              :value="item.key"
              :label="item.val"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="院校:" prop="collegename">
          <el-input v-model="dynamicValidateForm.collegename" disabled />
        </el-form-item>
        <el-form-item label="专业:" prop="majorname">
          <el-input v-model="dynamicValidateForm.majorname" disabled />
        </el-form-item>
        <el-form-item label="学制:" prop="xuezhikey">
          <el-select v-model="dynamicValidateForm.xuezhikey" clearable>
            <el-option
              v-for="item in xuezhi"
              :key="item.key"
              :value="item.key"
              :label="item.val"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考生号:" prop="examineenumber">
          <el-input v-model.trim="dynamicValidateForm.examineenumber" clearable/>
        </el-form-item>
        <el-form-item label="学号:" prop="code">
          <el-input v-model.trim="dynamicValidateForm.code" clearable/>
        </el-form-item>
        <el-form-item label="准考证号:" prop="ticketnumber">
          <el-input v-model.trim="dynamicValidateForm.ticketnumber" clearable/>
        </el-form-item>
        <el-form-item label="函授站:" prop="schoolorgname2">
          <el-select
            v-model="dynamicValidateForm.schoolorgname2"
            clearable
            @change="onGuakaoschoolorChange($event, 'dynamicValidateForm')"
          >
					<el-option
					  v-for="(value, key) in map"
						:key="key"
					  :value="key"
					  :label="value"
						clearable
					></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="上课地点:（开班点）" prop="classaddress">
          <el-select
            v-model="dynamicValidateForm.classaddress"
            clearable
             @change="onClassaddressChange($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in collegecoporgList"
              :key="item.classid"
              :value="item.classid"
              :label="item.classname"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="项目类型:" prop="projecttype">
          <el-select v-model="dynamicValidateForm.projecttype" clearable>
            <el-option
              v-for="item in educationList"
              :key="item.key"
              :value="item.val"
              :label="item.val"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            v-model.trim="dynamicValidateForm.remark"
						:rows="6"
						placeholder="请输入内容"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAddOrEditForm('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 申请异动 -->
    <el-dialog
      class="btn-2b5a95"
      width="35%"
			v-dialogDrag
			:close-on-click-modal='false'
      :title="dialogTitle"
      :visible.sync="showTransactionModal"
      v-if="showTransactionModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        class="demo-dynamic form-item-w-100"
        status-icon
        :rules="rules"
      >
        <el-form-item label="学生姓名:">
          <el-input v-model="dynamicValidateForm.studentname" disabled />
        </el-form-item>
        <el-form-item prop="transactiontype" label="异动类型:">
          <el-select v-model="dynamicValidateForm.transactiontype" clearable>
            <el-option
              v-for="item in changeTypes"
              :key="item.value"
              :value="item.label"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="原院校:">
          <el-input v-model="dynamicValidateForm.collegename" disabled />
        </el-form-item>
        <el-form-item label="原专业:">
          <el-input v-model="dynamicValidateForm.majorname" disabled />
        </el-form-item>
        <div
          v-if="
            dynamicValidateForm.transactiontype === '转学转专业' ||
            dynamicValidateForm.transactiontype === '转学' ||
            dynamicValidateForm.transactiontype === '转专业'
          "
        >
          <el-form-item label="转后院校:" prop="collegename2">
            <el-input v-model="dynamicValidateForm.collegename2" disabled />
            <el-button
              type="primary"
              @click="
                onSelectData(
                  collegemajor_dialogresultall,
                  'showAcademyModal',
									'',
									'selectSearchForm'
                )
              "
              >选择</el-button
            >
          </el-form-item>
          <el-form-item label="转后专业:" prop="majorname2">
            <el-input v-model="dynamicValidateForm.majorname2" disabled />
          </el-form-item>
        </div>
        <div v-if="dynamicValidateForm.transactiontype === '转学习形式'">
          <el-form-item label="原学习形式:">
            <el-input v-model="dynamicValidateForm.studytypeval" disabled />
          </el-form-item>
          <el-form-item label="转后学习形式:" prop="studytypeval2">
            <el-select v-model="dynamicValidateForm.studytypeval2" clearable>
              <el-option
                v-for="item in studytypeval"
                :key="item.key"
                :value="item.val"
                :label="item.val"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
				<el-form-item label="函授站:" prop="guakaoschoolorgname">
				  <el-select
				    v-model="dynamicValidateForm.guakaoschoolorgname"
				    clearable
						@change="onGuakaoschoolorChange($event, 'dynamicValidateForm')"
				  >
					<el-option
					  v-for="(value, key) in map"
						:key="key"
					  :value="key"
					  :label="value"
					></el-option>
				  </el-select>
				</el-form-item>
				<el-form-item label="上课地点:（开班点）" prop="classaddress">
				  <el-select
				    v-model="dynamicValidateForm.classaddress"
						clearable
				   @change="onClassaddressChange($event, 'dynamicValidateForm')"
				  >
				    <el-option
				      v-for="item in collegecoporgList"
				      :key="item.classid"
				      :value="item.classid"
				      :label="item.classname"
				    ></el-option>
				  </el-select>
				</el-form-item>
        <el-form-item prop="picurl" label="异动凭证（图片）：">
          <el-input v-model="dynamicValidateForm.picurl" disabled />
          <el-upload
            class="upload-demo"
            :action="$store.state.global.baseUrl"
            accept=".jpg,.jpeg,.png,"
            multiple
            :show-file-list="false"
            :before-upload="beforeUpload"
            :http-request="uploadFile"
          >
            <el-button type="primary" class="select-btn">点击上传</el-button>
            <div slot="tip" class="el-upload__tip"></div>
          </el-upload>
        </el-form-item>
        <el-form-item label="备注:">
          <el-input type="textarea" v-model.trim="dynamicValidateForm.remark" clearable/>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="transactionsave('dynamicValidateForm')"
          >提交
        </el-button>
      </span>
    </el-dialog>
    <!-- 查看异动列表 -->
    <el-dialog
      class="btn-2b5a95"
      width="1000px"
			v-dialogDrag
			:close-on-click-modal='false'
      title="查看异动列表"
      :visible.sync="showTransacListModal"
      v-if="showTransacListModal"
      append-to-body
    >
      <!-- 操作按钮 -->
      <div class="select-btn" style="margin-bottom: 20px;">
        <el-button type="primary" @click="btnEditDetial">修改 </el-button>
        <el-button type="primary" @click="btnDelDetial">删除 </el-button>
        <el-button type="primary" @click="btnSuccess">办理结束 </el-button>
      </div>
      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				border
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" width="100" />
        <el-table-column label="学生" prop="studentname" align="center" />
        <el-table-column
          label="异动类型"
          prop="transactiontype"
          align="center"
          width="140"
        >
        </el-table-column>
        <el-table-column
          label="原院校"
          prop="collegename1"
          align="center"
          width="140"
        />
        <el-table-column
          label="原专业"
          prop="majorname1"
          align="center"
          width="140"
        />
        <el-table-column
          label="原学习形式"
          prop="studytypeval1"
          align="center"
          width="140"
        />
        <el-table-column
          label="转后院校"
          prop="collegename2"
          align="center"
          width="140"
        />
        <el-table-column
          label="转后专业"
          prop="majorname2"
          align="center"
          width="140"
        />
        <el-table-column
          label="转后学习形式"
          prop="studytypeval2"
          align="center"
          width="130"
        />
        <el-table-column
          label="录入人"
          prop="realname"
          align="center"
          width="120"
        />
        <el-table-column label="录入时间" align="center" width="160">
          <template slot-scope="scope">
            {{ scope.row.inserttime | formatDate("YYYY-MM-DD HH:mm:ss") }}
          </template>
        </el-table-column>
        <el-table-column
          label="教学点"
          prop="schoolorgname"
          align="center"
          width="130"
        />
        <el-table-column label="办理状态" align="center">
          <template slot-scope="scope">
            {{ status[scope.row.status] }}
          </template>
        </el-table-column>
        <el-table-column label="办理成功" align="center">
          <template slot-scope="scope">
            {{ isflag[scope.row.isflag] }}
          </template>
        </el-table-column>
        <el-table-column label="凭证图片" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="downloadFile(scope.row)"
              >下载</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        @current-change="
          (val, data) => handleSelectChange(val, selectSearchForm)
        "
        :current-page="pageinfo.pageindex"
        :page-size="pageinfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>

    <!-- 查看异动的修改 -->
    <el-dialog
      class="btn-2b5a95"
      width="40%"
			v-dialogDrag
			:close-on-click-modal='false'
      :title="dialogTitle"
      :visible.sync="showTransacEditModal"
      v-if="showTransacEditModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="30%"
        class="demo-dynamic form-item-w-100"
        status-icon
        :rules="rules"
      >
        <el-form-item label="姓名">
          <el-input
            v-model="dynamicValidateForm.studentname"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item prop="transactiontype" label="异动类型:">
          <el-select
            v-model="dynamicValidateForm.transactiontype"
            @change="changeTransaction"
            clearable
          >
            <el-option
              v-for="item in changeTypes"
              :key="item.value"
              :value="item.label"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="原院校">
          <el-input
            v-model="dynamicValidateForm.collegename1"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="原专业">
          <el-input
            v-model="dynamicValidateForm.majorname1"
            disabled
          ></el-input>
        </el-form-item>
        <div
          v-if="
            dynamicValidateForm.transactiontype === '转学转专业' ||
            dynamicValidateForm.transactiontype === '转学' ||
            dynamicValidateForm.transactiontype === '转专业'
          "
        >
          <el-form-item label="转后院校:">
            <el-input v-model="dynamicValidateForm.collegename2" disabled />
            <el-button
              type="primary"
              class="select-btn"
              @click="
                onSelectData(
                  collegemajor_dialogresultall,
                  'showAcademyModal',
									'',
                  'selectSearchForm'
                )
              "
              >选择</el-button
            >
          </el-form-item>
          <el-form-item label="转后专业">
            <el-input
              v-model="dynamicValidateForm.majorname2"
              disabled
            ></el-input>
          </el-form-item>
        </div>
        <div v-if="dynamicValidateForm.transactiontype === '转学习形式'">
          <el-form-item label="原学习形式:">
            <el-input v-model="dynamicValidateForm.studytypeval1" disabled />
          </el-form-item>
          <el-form-item label="转后学习形式:" prop="studytypeval2">
            <el-select v-model="dynamicValidateForm.studytypeval2" clearable>
              <el-option
                v-for="item in studytypeval"
                :key="item.key"
                :value="item.val"
                :label="item.val"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-form-item prop="picurl" label="异动凭证（图片）：">
          <el-input v-model="dynamicValidateForm.picurl" disabled />
          <el-upload
            class="upload-demo"
            :action="$store.state.global.baseUrl"
            accept=".jpg,.jpeg,.png,"
            multiple
            :show-file-list="false"
            :before-upload="beforeUpload"
            :http-request="uploadFile"
          >
            <el-button type="primary" class="select-btn">点击上传</el-button>
            <div slot="tip" class="el-upload__tip"></div>
          </el-upload>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            v-model.trim="dynamicValidateForm.remark"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveDetail('dynamicValidateForm')"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!--办理结束-->
    <el-dialog
      class="btn-2b5a95"
      width="40%"
			v-dialogDrag
			:close-on-click-modal='false'
      :title="dialogTitle"
      :visible.sync="showApprove2Modal"
      v-if="showApprove2Modal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="30%"
        class="demo-dynamic form-item-w-100"
        status-icon
        :rules="rules"
      >
        <el-form-item label="是否办理成功:" prop="isflag">
          <el-select v-model="dynamicValidateForm.isflag" clearable>
            <el-option value="是"></el-option>
            <el-option value="否"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            type="textarea"
            v-model="dynamicValidateForm.remark"
						clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveApprove2('dynamicValidateForm')"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 选择德立学生-->
    <el-dialog
      class="btn-2b5a95"
      width="80%"
			v-dialogDrag
			:close-on-click-modal='false'
      @close="onCloseSelect"
      title="选择德立学生："
      :visible.sync="showDeliModal"
      v-if="showDeliModal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmDeli">确定</el-button>

      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="学生姓名:" prop="qianzainame">
              <el-input
								v-model.trim="selectSearchForm.qianzainame"
								@keyup.enter.native="seach"
								clearable />
            </el-form-item>
            <el-form-item label="手机号码:" prop="mobile">
              <el-input
								v-model.trim="selectSearchForm.mobile"
								@keyup.enter.native="seach"
								clearable />
            </el-form-item>
            <el-form-item label="所属教学点:" prop="schoolorgname">
              <el-input
								v-model.trim="selectSearchForm.schoolorgname"
								@keyup.enter.native="seach"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="onSelectSearchClick(selectTableUrl, null, selectSearchForm)"
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>

      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				border
				:key="key"
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="学生姓名" prop="qianzainame" align="center" />
        <el-table-column label="手机号码" prop="mobile" align="center" />
        <el-table-column label="项目类型" prop="projecttype" align="center" />
        <el-table-column label="层次" prop="levelval" align="center" />
        <el-table-column
          label="预报名类型"
          prop="baomingtypeval"
          align="center"
        />
        <el-table-column
          label="第一志愿院校"
          prop="collegename1"
          align="center"
        />
        <el-table-column
          label="第二志愿院校"
          prop="collegename2"
          align="center"
        />
        <el-table-column
          label="应收金额"
          prop="shouldrecvmoney"
          align="center"
        />
        <el-table-column
          label="实收学位费"
          prop="realrecvmoney"
          align="center"
        />
        <el-table-column label="收据序号" prop="shoujucode" align="center" />
        <el-table-column
          label="所属教学点"
          prop="schoolorgname"
          align="center"
        />
      </el-table>
      <el-pagination
        background
        @current-change="
          (val, data, prop, page) =>
            handleSelectChange(val, {
              schoolorgid: dynamicValidateForm.schoolorgid,
            })
        "
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>
    <!-- 选择专业-->
    <el-dialog
      class="btn-2b5a95"
      width="1200px"
			v-dialogDrag
			:close-on-click-modal='false'
      @close="onCloseSelect"
      title="选择专业："
      :visible.sync="showCollegeModal"
      v-if="showCollegeModal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmCollegemajor">确定</el-button>

      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="院校:" prop="collegename">
              <el-input
								v-model.trim="selectSearchForm.collegename"
								@keyup.enter.native="onSelectSearchClick(selectTableUrl, null, selectSearchForm)"
								clearable />
            </el-form-item>
            <el-form-item label="专业:" prop="name">
              <el-input
								v-model.trim="selectSearchForm.name"
								@keyup.enter.native="onSelectSearchClick(selectTableUrl, null, selectSearchForm)"
								clearable />
            </el-form-item>
            <el-form-item label="专业层次:" prop="majorlevelval">
              <el-input
								v-model.trim="selectSearchForm.majorlevelval"
								@keyup.enter.native="onSelectSearchClick(selectTableUrl, null, selectSearchForm)"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="onSelectSearchClick(selectTableUrl, null, selectSearchForm)"
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>

      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				border
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="院校" prop="collegename" align="center" />
        <el-table-column label="专业" prop="name" align="center" />
        <el-table-column label="层次" prop="majorlevelval" align="center" />
        <el-table-column label="类别" prop="majortypeval" align="center" />
      </el-table>
      <el-pagination
        background
        @current-change="
          (val, data) => handleSelectChange(val, selectSearchForm)
        "
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>
    <!-- 选择院校-->
    <el-dialog
      class="btn-2b5a95"
      width="1200px"
			v-dialogDrag
			:close-on-click-modal='false'
      @close="onCloseSelect"
      title="选择院校："
      :visible.sync="showAcademyModal"
      v-if="showAcademyModal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmAcademy">确定</el-button>
      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="院校:" prop="collegename">
              <el-input
								v-model.trim="selectSearchForm.collegename"
								@keyup.enter.native="onSelectSearchClick(selectTableUrl, null, selectSearchForm)"
								clearable />
            </el-form-item>
            <el-form-item label="专业:" prop="name">
              <el-input
								v-model.trim="selectSearchForm.name"
								@keyup.enter.native="onSelectSearchClick(selectTableUrl, null, selectSearchForm)"
								clearable />
            </el-form-item>
            <el-form-item label="专业层次:" prop="majorlevelval">
              <el-input
								v-model.trim="selectSearchForm.majorlevelval"
								@keyup.enter.native="onSelectSearchClick(selectTableUrl, null, selectSearchForm)"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="onSelectSearchClick(selectTableUrl, null, selectSearchForm)"
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>
      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				border
				stripe
				:key="key"
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="专业" prop="name" align="center" />
        <el-table-column label="院校" prop="collegename" align="center" />
        <el-table-column label="专业层次" prop="majorlevelval" align="center" />
        <el-table-column
          label="是否招生标志"
          prop="enableflag"
          align="center"
        >
				<template slot-scope="scope">
					{{ scope.row.enableflag == 1 ? "是" : "否" }}
				</template>
				</el-table-column>
      </el-table>
      <el-pagination
        background
        @current-change="
          (val, data) => handleSelectChange(val, selectSearchForm)
        "
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>

		<el-dialog
		  class="btn-2b5a95"
		  width="35%"
			v-dialogDrag
		  title="添加备注："
		  :visible.sync="remarkModel"
			:close-on-click-modal='false'
			v-if="remarkModel"
		  append-to-body
		>
		  <el-form
		    :model="rulesForm"
		    ref="rulesForm"

		    class="demo-dynamic form-item-w-100"
		    :rules="rules"
		    status-icon
		  >
		    <el-form-item
		      prop="remark"
		      label="备注："
		      :rules="[{ required: true, message: '必填字段', trigger: 'blur' }]"
		    >
		      <el-input v-model.trim="rulesForm.remark" clearable></el-input>
		    </el-form-item>

		  </el-form>
		  <span slot="footer" class="dialog-footer">
		    <el-button type="primary" @click="submitRemark('rulesForm')"
		      >提交
		    </el-button>
		  </span>
		</el-dialog>


		<div id="RightOffside" class="RightOffside" style="display: block;" v-show="showDetailModal">
			<!-- 按钮组 -->
			<div class="Offside_btnBox">
				<div class="Offside_Btn Offside_CloseBtn" @click="handleShow"></div>
				<div class="Offside_Btn"
					v-for="(item,index) in tabList"
					:key="index"
					:class="{active:currentClass === index}"
					@click="toggleTab(index)"
				>
					{{item}}
				</div>
			</div>
			<div class="OrderStatusTips">
				<span class="pull-left text-blue">人员信息</span>
			</div>
			<!-- 切换栏 -->
			<div>
				<div class="OrderContentBox" v-show="currentTab === 0">
					<div class="order-card x-basic abase-card" style="width:800px;background-color: #D1EEEE; margin-bottom: 30px;">
						<div class="top_el">基本信息</div>
							<el-form :model="allDetailForm.vstudentregister">
								<table class="tlist_el" style="width: 100%;" bgcolor="#D1EEEE" cellpadding="5px">
									<tbody>
										<tr>
											<td class="ltd_el" >姓名：</td>
											<td class="ltd_tag">
												{{vstudentpotential.studentname }}
											</td>
											<td class="ltd_el">学号：</td>
											<td class="ltd_tag">
												{{vstudentpotential.code}}
											</td>
											<td class="ltd_el">考生号：</td>
											<td class="ltd_tag">
												{{vstudentpotential.examineenumber}}
											</td>
											<td rowspan="7" colspan="6" style="text-align: center;">
													<el-image
														style="width: 184px; height: 152px;object-fit: contain;"
														:src="vstudentpotential.photo ? $url.upload + vstudentpotential.photo : ''"
														@click="expand(vstudentpotential.photo ? $url.upload + vstudentpotential.photo : '')"
														:preview-src-list="photosrcList"
														:z-index="99999"
													>
														<div slot="error" class="image-slot el-image__error">
															<i class="el-icon-picture-outline"></i>
															<span>大一寸照片</span>
														</div>
													</el-image>
											</td>
										</tr>
										<tr>
											<td class="ltd_el">性别：</td>
											<td class="ltd_tag">
												{{vstudentpotential.sexval}}
											</td>
											<td class="ltd_el">民族：</td>
											<td class="ltd_tag">
													{{vstudentpotential.nationval}}
											</td>
											<td class="ltd_el">出生年月：</td>
											<td class="ltd_tag">
													{{vstudentpotential.birthday | formatDate("YYYY-MM-DD")}}
											</td>
										</tr>
										<tr>
											<td colspan="2">证件类型：</td>
											<td class="ltd_tag">
												{{vstudentpotential.idcardtypeval}}
											</td>
											<td colspan="2">证件号码：</td>
											<td class="ltd_tag">
													{{vstudentpotential.studentidcard}}
											</td>
										</tr>
										<tr>
											<td colspan="2">联系电话：</td>
											<td class="ltd_tag">
													{{vstudentpotential.mobile | phoneFilter()}}
											</td>
											<td colspan="2">紧急联系号码：</td>
											<td class="ltd_tag">
													{{vstudentpotential.emergentphone}}
											</td>
										</tr>
										<tr>
											<td>学校：</td>
											<td class="ltd_tag">
												{{vstudentpotential.collegename}}
											</td>
											<td>层次：</td>
											<td class="ltd_tag">
													{{vstudentpotential.levelval}}
											</td>
											<td>专业：</td>
											<td class="ltd_tag">
													{{vstudentpotential.majorname}}
											</td>
										</tr>
										<tr>
											<td>教学点：</td>
											<td class="ltd_tag">
													{{vstudentpotential.schoolorgname}}
											</td>
											<td>函授站：</td>
											<td class="ltd_tag">
													{{vstudentpotential.guakaoschoolorgname}}
											</td>
											<td>上课点：</td>
											<td class="ltd_tag">
													{{vstudentpotential.classaddress}}
											</td>
										</tr>
										<tr>
											<td>曾用名：</td>
											<td class="ltd_tag">
												无
											</td>
											<td>籍贯：</td>
											<td class="ltd_tag">
												{{vstudentpotential.comefrom}}
											</td>
											<td>邮箱：</td>
											<td class="ltd_tag">
												{{vstudentpotential.email}}
											</td>
										</tr>
										<tr>
											<td colspan="2">邮政编码：</td>
											<td class="ltd_tag">
												{{vstudentpotential.homezipcode}}
											</td>
											<td>微信号：</td>
											<td colspan="7" class="ltd_tag">
												{{vstudentpotential.weixin}}
											</td>
										</tr>
										<tr>
											<td colspan="2" rowspan="2" align="left">入学成绩</td>
											<td class="ltd_tag">档案分</td>
											<td v-for="(item,index) in vstudentpotential.list3" class="ltd_tag">
												{{item.coursename}}
											</td>
											<td v-if="vstudentpotential.points == true" class="ltd_tag">特征分</td>
										</tr>
										<tr>
											<td class="ltd_tag">{{vstudentpotential.total}}</td>
											<td v-for="(item,index) in vstudentpotential.list3" class="ltd_tag">
												{{item.score1}}
											</td>
											<td v-if="vstudentpotential.points == true" class="ltd_tag">
												<template>
													{{vstudentpotential.points}}
												</template>
											</td>
										</tr>
										<tr>
											<td>通讯地址：</td>
											<td colspan="9" style="text-align: center;">
												 广州市天河区天源路767号B栋三楼德立教育
											</td>
										</tr>
										<tr>
											<td>学历类型：</td>
											<td class="ltd_tag">
													{{vstudentpotential.projecttype}}
											</td>
											<td>年级：</td>
											<td class="ltd_tag">
													{{vstudentpotential.grade}}
											</td>
											<td>形式：</td>
											<td class="ltd_tag">
													{{vstudentpotential.studytypeval}}
											</td>
											<td>学制：</td>
											<td class="ltd_tag">
												{{vstudentpotential.xuezhival}}
											</td>
										</tr>
										<tr>
											<td>单位：</td>
											<td colspan="9" class="ltd_tag">
												广东省德立教育有限公司
											</td>
										</tr>
										<tr>
											<td colspan="2" rowspan="2" align="left">
												<p>社会关系及</p>
												<p>主要家庭成员</p>
											</td>
											<td>姓名</td>
											<td>称谓</td>
											<td>年龄</td>
											<td>在何处任职</td>
											<td></td>
											<td></td>
										</tr>
										<tr v-for="(item,index) in vstudentpotential.list1">
											<td class="ltd_tag">{{item.resumename}}</td>
											<td class="ltd_tag">{{item.appellation}}</td>
											<td class="ltd_tag">{{item.age}}</td>
											<td class="ltd_tag">{{item.appoint}}</td>
											<td class="ltd_tag"></td>
											<td class="ltd_tag"></td>
										</tr>
										<tr>
											<td colspan="2" rowspan="2" align="left">
												入学前简历
											</td>
											<td>起始时间</td>
											<td>在何地(校)工作或者学习</td>
											<td>任何职务</td>
											<td>学制</td>
											<td>工作年限</td>
											<td>毕业肄业</td>
										</tr>
										<tr v-for="(item,index) in vstudentpotential.list2">
											<td class="ltd_tag">{{item.duration}}</td>
											<td class="ltd_tag">{{item.occupation}}</td>
											<td class="ltd_tag">{{item.post}}</td>
											<td class="ltd_tag">{{item.xuezhival}}</td>
											<td class="ltd_tag">{{item.workingyears}}</td>
											<td class="ltd_tag">{{item.graduation}}</td>
										</tr>
									</tbody>
							</table>
						</el-form>
					</div>
				</div>
				<div class="OrderContentBox" v-show="currentTab === 1">
					<el-table
						:data="allDetailForm.studentlist"
						border
						stripe
						:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
						style="width: 100%">
					  <el-table-column
					    label="流水号"
					    prop="id"
					    align="center"
					    width="100"
					  ></el-table-column>
					  <el-table-column
					    label="学生姓名"
					    prop="qianzainame"
					    align="center"
					    width="150"
					  >
					  </el-table-column>
					  <el-table-column
					    label="手机号"
					    prop="mobile"
					    align="center"
					    width="150"
					  >
							<template slot-scope="scope">
							  {{ scope.row.mobile | hideMobile(scope.row.followuserid)}}
							</template>
					  </el-table-column>
					  <el-table-column
					    label="报读年级"
					    prop="grade"
					    align="center"
					    width="100"
					  ></el-table-column>
					  <el-table-column
					    label="层次"
					    prop="levelval"
					    align="center"
					    width="100"
					  ></el-table-column>
					  <el-table-column label="总部给予" align="center" width="100">
					    <template slot-scope="scope">
					      {{ isflag[scope.row.hqsend] }}
					    </template>
					  </el-table-column>
					  <el-table-column
					    label="学习形式"
					    prop="studytypeval"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="类型"
					    prop="baomingtypeval"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="应收"
					    prop="shouldrecvmoney"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="实收"
					    prop="realrecvmoney"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="退费"
					    prop="feebackmoney"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="收据编号"
					    prop="shoujucode"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="跟进人"
					    prop="followusername"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column label="报名时间" align="center" width="100">
					    <template slot-scope="scope">
					      {{ scope.row.inserttime | formatDate("YYYY-MM-DD hh:mm:ss") }}
					    </template>
					  </el-table-column>
					  <el-table-column
					    label="项目类型"
					    prop="projecttype"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="合作机构"
					    prop="organization"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="所属教学点"
					    prop="schoolorgname"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					</el-table>
				</div>
				<div class="OrderContentBox" v-show="currentTab === 2">
					<el-table
					  :data="allDetailForm.studentmoneyrecvlist"
					  style="width: 100%"
						border
						stripe
						:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
					>
					  <el-table-column label="流水号" prop="id" align="center" />
					  <el-table-column
					    label="学生姓名"
					    prop="studentname"
					    align="center"
					  />
					  <el-table-column label="手机号码" prop="mobile" align="center">
							<template slot-scope="scope">
							  {{ scope.row.mobile | hideMobile(scope.row.followuserid)}}
							</template>
						</el-table-column>
					  <el-table-column label="身份证" prop="idcard" align="center" />
					  <el-table-column
					    label="费用类型"
					    prop="feetypeval"
					    align="center"
					  />
					  <el-table-column
					    label="账号名称"
					    prop="accountname"
					    align="center"
					  />
					  <el-table-column label="金额" prop="recvmoney" align="center" />
					  <el-table-column label="缴费时间" prop="recvtime" align="center">
					    <template slot-scope="scope">
					      {{ scope.row.recvtime | formatDate("YYYY-MM-DD hh:mm:ss") }}
					    </template>
					  </el-table-column>
					  <el-table-column
					    label="收据编号"
					    prop="shoujucode"
					    align="center"
					  />
					  <el-table-column
					    label="经手人"
					    prop="insertusername"
					    align="center"
					  />
					  <el-table-column
					    label="所属教学"
					    prop="schoolorgname"
					    align="center"
					  />
					  <el-table-column label="录入时间" align="center">
					    <template slot-scope="scope">
					      {{ scope.row.inserttime | formatDate("YYYY-MM-DD hh:mm:ss") }}
					    </template>
					  </el-table-column>
					  <el-table-column label="冲账标志" align="center">
					    <template slot-scope="scope">
					      {{ chongzhanflag[scope.row.invertflag] }}
					    </template>
					  </el-table-column>
					  <el-table-column label="被冲流水" prop="invertid" align="center" />
					  <el-table-column label="冲账时间" align="center">
					    <template slot-scope="scope">
					      {{ scope.row.inverttime | formatDate("YYYY-MM-DD hh:mm:ss") }}
					    </template>
					  </el-table-column>
					</el-table>
				</div>
				<div class="OrderContentBox" v-show="currentTab === 3">
					<el-table
						:data="allDetailForm.techfudaolist"
						border
						stripe
						:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
						style="width: 100%">
					  <el-table-column
					    label="流水号"
					    prop="id"
					    align="center"
					    width="100"
					  ></el-table-column>
					  <el-table-column
					    label="学生姓名"
					    prop="studentname"
					    align="center"
					    width="150"
					  >
					  </el-table-column>
					  <el-table-column
					    label="手机号"
					    prop="mobile"
					    align="center"
					    width="150"
					  >
							<template slot-scope="scope">
							  {{ scope.row.mobile | hideMobile(scope.row.followuserid)}}
							</template>
					  </el-table-column>
					  <el-table-column
					    label="班别名称"
					    prop="fudaoclasstypename"
					    align="center"
					    width="100"
					  ></el-table-column>
					  <el-table-column
					    label="培训类型"
					    prop="fudaotypeval"
					    align="center"
					    width="100"
					  ></el-table-column>
					  <el-table-column label="报读时间" align="center" width="100">
					    <template slot-scope="scope">
					      {{ scope.row.regdate | formatDate("YYYY-MM-DD hh:mm:ss") }}
					    </template>
					  </el-table-column>
					  <el-table-column
					    label="收款标识"
					    prop="shoukuanremark"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="应收"
					    prop="totalmoney"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="减免金额"
					    prop="extrasubmoney"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="银行/现金"
					    prop="bankorcash"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="实收"
					    prop="recvmoney"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="退费金额"
					    prop="feebackmoney"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="收据编号"
					    prop="shoujucode"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="跟进人"
					    prop="followusername"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="所属教学点"
					    prop="schoolorgname"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					</el-table>
				</div>
				<div class="OrderContentBox" v-show="currentTab === 4">
					<el-table
					  :data="allDetailForm.studentregisterlist"
					  style="width: 100%"
						border
						stripe
						:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
					>
					  <el-table-column
					    label="流水号"
					    prop="id"
					    align="center"
					    width="100"
					  ></el-table-column>
					  <el-table-column
					    label="姓名"
					    prop="studentname"
					    align="center"
					    width="150"
					  >
					  </el-table-column>
					  <el-table-column
					    label="手机"
					    prop="mobile"
					    align="center"
					    width="150"
					  >
							<template slot-scope="scope">
							  {{ scope.row.mobile | hideMobile(scope.row.followuserid)}}
							</template>
					  </el-table-column>
					  <el-table-column
					    label="学号"
					    prop="code"
					    align="center"
					    width="100"
					  ></el-table-column>
					  <el-table-column
					    label="身份证"
					    prop="studentidcard"
					    align="center"
					    width="100"
					  ></el-table-column>
					  <el-table-column
					    label="院校"
					    prop="collegename"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="专业"
					    prop="majorname"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="学年"
					    prop="iyear"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="年级"
					    prop="grade"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="层次"
					    prop="levelval"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="班主任"
					    prop="followusername"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="函授站"
					    prop="guakaoschoolorgname"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="状态"
					    prop="xuejistatusval"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="项目类型"
					    prop="projecttype"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="教学点"
					    prop="schoolorgname"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					</el-table>
				</div>
				<div class="OrderContentBox" v-show="currentTab === 5">
					<el-table
					  :data="allDetailForm.studentgenzonglist"
					  style="width: 100%"
						border
						stripe
						:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
					>
					  <el-table-column label="流水号" prop="id" align="center" />
					  <el-table-column
					    label="潜在学生"
					    prop="qianzainame"
					    align="center"
					  />
					  <el-table-column label="学生手机" prop="mobile" align="center" >
							<template slot-scope="scope">
							  {{ scope.row.mobile | hideMobile(scope.row.followuserid)}}
							</template>
						</el-table-column>
					  <el-table-column label="QQ" prop="qq" align="center" />
					  <el-table-column
					    label="跟进内容"
					    prop="followcontent"
					    align="center"
					  />
					  <el-table-column label="跟进时间" align="center">
					    <template slot-scope="scope">
					      {{ scope.row.inserttime | formatDate("YYYY-MM-DD HH:mm:ss") }}
					    </template>
					  </el-table-column>
					  <el-table-column label="跟进人" prop="username" align="center" />
					  <el-table-column
					    label="所属教学点"
					    prop="schoolorgname"
					    align="center"
					  />
					</el-table>
				</div>
				<div class="OrderContentBox" v-show="currentTab === 6">
					<div class="demo-image__preview demo-image__error">
						<el-card shadow="hover" class="el-card_image_next">
							<p class="demonstration text-align text-blue">身份证正面</p>
							<div class="image_pic_el">
								<el-image
									class="adaptation"
									:src="vstudentpotential.idphoto ? $url.upload + vstudentpotential.idphoto : ''"
									@click="vbs(vstudentpotential.idphoto ? $url.upload + vstudentpotential.idphoto : '')"
									:preview-src-list="srcList"
									:z-index="99999"
								>
									<div slot="error" class="image-slot el-image__error">
										<i class="el-icon-picture-outline"></i>
										<span>暂无图片</span>
									</div>
								</el-image>
							</div>
						</el-card>
						<el-card shadow="hover" class="el-card_image_next">
							<p class="demonstration text-align text-blue">身份证反面</p>
							<div class="image_pic_el">
								<el-image
									class="adaptation"
									:src="vstudentpotential.idphoto2 ? $url.upload + vstudentpotential.idphoto2 : ''"
									@click="vbs(vstudentpotential.idphoto2 ? $url.upload + vstudentpotential.idphoto2 : '')"
									:preview-src-list="srcList"
									:z-index="99999"
								>
									<div slot="error" class="image-slot el-image__error">
										<i class="el-icon-picture-outline"></i>
										<span>暂无图片</span>
									</div>
								</el-image>
							</div>
						</el-card>
						<el-card shadow="hover" class="el-card_image_next">
							<p class="demonstration text-align text-blue">照片</p>
							<div class="image_pic_el">
								<el-image
									class="adaptation"
									:src=" vstudentpotential.photo ? $url.upload + vstudentpotential.photo : ''"
									@click="vbs(vstudentpotential.photo ? $url.upload + vstudentpotential.photo : '')"
									:preview-src-list="srcList"
									:z-index="99999"
								>
									<div slot="error" class="image-slot el-image__error">
										<i class="el-icon-picture-outline"></i>
										<span>暂无图片</span>
									</div>
								</el-image>
							</div>
						</el-card>
						<el-card shadow="hover" class="el-card_image_next">
							<p class="demonstration text-align text-blue">户口本首页</p>
							<div class="image_pic_el">
								<el-image
									class="adaptation"
									:src=" vstudentpotential.registerphoto ? $url.upload + vstudentpotential.registerphoto : ''"
									@click="vbs(vstudentpotential.registerphoto ? $url.upload + vstudentpotential.registerphoto : '')"
									:preview-src-list="srcList"
									:z-index="99999"
								>
									<div slot="error" class="image-slot el-image__error">
										<i class="el-icon-picture-outline"></i>
										<span>暂无图片</span>
									</div>
								</el-image>
							</div>
						</el-card>
						<el-card shadow="hover" class="el-card_image_next">
							<p class="demonstration text-align text-blue">户口本人页</p>
							<div class="image_pic_el">
								<el-image
									class="adaptation"
									:src=" vstudentpotential.registerphoto2 ? $url.upload + vstudentpotential.registerphoto2 : ''"
									@click="vbs(vstudentpotential.registerphoto2 ? $url.upload + vstudentpotential.registerphoto2 : '')"
									:preview-src-list="srcList"
									:z-index="99999"
								>
									<div slot="error" class="image-slot el-image__error">
										<i class="el-icon-picture-outline"></i>
										<span>暂无图片</span>
									</div>
								</el-image>
							</div>
						</el-card>
						<el-card shadow="hover" class="el-card_image_next">
							<p class="demonstration text-align text-blue">毕业证</p>
							<div class="image_pic_el">
								<el-image
									class="adaptation"
									:src="vstudentpotential.schoolphoto ? $url.upload + vstudentpotential.schoolphoto : ''"
									@click="vbs(vstudentpotential.schoolphoto ? $url.upload + vstudentpotential.schoolphoto : '')"
									:preview-src-list="srcList"
									:z-index="99999"
								>
									<div slot="error" class="image-slot el-image__error">
										<i class="el-icon-picture-outline"></i>
										<span>暂无图片</span>
									</div>
								</el-image>
							</div>
						</el-card>
						<el-card shadow="hover" class="el-card_image_next">
							<p class="demonstration text-align text-blue">注册备案表</p>
							<div class="image_pic_el">
								<el-image
									class="adaptation"
									:src=" vstudentpotential.regphoto ? $url.upload + vstudentpotential.regphoto : ''"
									@click="vbs(vstudentpotential.regphoto ? $url.upload + vstudentpotential.regphoto : '')"
									:preview-src-list="srcList"
									:z-index="99999"
								>
									<div slot="error" class="image-slot el-image__error">
										<i class="el-icon-picture-outline"></i>
										<span>暂无图片</span>
									</div>
								</el-image>
							</div>
						</el-card>
						<el-card shadow="hover" class="el-card_image_next">
							<p class="demonstration text-align text-blue">居住证明</p>
							<div class="image_pic_el">
								<el-image
									class="adaptation"
									:src=" vstudentpotential.residence ? $url.upload + vstudentpotential.residence : ''"
									@click="vbs(vstudentpotential.residence ? $url.upload + vstudentpotential.residence : '')"
									:preview-src-list="srcList"
									:z-index="99999"
								>
									<div slot="error" class="image-slot el-image__error">
										<i class="el-icon-picture-outline"></i>
										<span>暂无图片</span>
									</div>
								</el-image>
							</div>
						</el-card>
					</div>
				</div>
			</div>
		</div>
    <Export
      :exportTitle="'在籍管理'"
      :transferDialog="transferDialog"
      :exportUrl="exportUrl"
      :exportexcelUrl="exportexcelUrl"
      :exportsaveUrl="exportsaveUrl"
      :searchForm="searchForm"
      @onCloseExport="onCloseExport"
    ></Export>

    <Import
      :importDialog="importDialog"
      :importSaveUrl="importSaveUrl"
      @onCloseImport="onCloseImport"
      @getDataList="getDataList"
      :downloadUrl="downloadUrl"
    ></Import>

  </div>
</template>


<script>
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import Export from "@/components/Export/index.vue";
import Import from "@/components/Import/index.vue";

import {
  studentregister_list,
  student_dialogresult,
  student_getstudent,
	collegemajor_dialogresult1,
  studentregister_input,
  studentregister_save2,
  studentregister_delete,
  studentregister_export,
  studentregister_exportexcel,
  studentregister_exportsave,
  studentregister_importsave2,
  studentregister_importcodesave,
  studentregister_inputedit,
  studentpotential_show,
	studentregister_show2,
  transaction_input2,
  transaction_input,
  transaction_save,
  transaction_list2,
  transaction_list,
  transaction_approve2,
  transaction_delete,
  transaction_managesuccess,
  studentregister_importxuejistatussave,
  studentregister_importorgsave,
  studentregister_download2,
  studentregister_financeapproval,
  studentregister_educationalapproval,
  collegecoporg_list,
	studentregister_getclassname,
	collegemajor_getcollegemajor,
	studentregister_updateremark,
    studentregister_export1,
    studentregister_export2
} from "@/request/api/allChildrenProject";
import {
  _pictureupload_save,
  _collegemajor_dialogresultall,
  _filedownload_save,
} from "@/request/api/public";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";
import {
  _projectTypes,
  _changeTypes,
  _changeType,
  _chongzhanflag,
} from "@/assets/js/filedValueFlag";

export default {
  mixins: [part],
  name: "studentregister",
  components: { Export, Import },
  props: {
    exportUrl: {
      default: () => studentregister_export,
    },
    exportexcelUrl: {
      default: () => studentregister_exportexcel,
    },
    exportsaveUrl: {
      default: () => studentregister_exportsave,
    },

    student_dialogresult: {
      default: () => student_dialogresult,
    },
    collegemajor_dialogresult1: {
      default: () => collegemajor_dialogresult1,
    },
    collegemajor_dialogresultall: {
      default: () => _collegemajor_dialogresultall,
    },
  },
  data() {
    return {
			rulesForm: {},
			key: '',
			currentTab: 0,
			currentClass: 0,
			tabList:['档案','学历报名','收费','培训','学籍','跟进','证件信息'],
			map:"",
			srcList: [],
			photosrcList: [],
      importSaveUrl: "",
      downloadUrl: "",
      transferDialog: false, //导出框
      importDialog: false, //导入框
      rowItem: null, //首表格项
      searchForm: {},
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      isflag: {
        1: "是",
        0: "否",
      },
      status: {
        1: "办理结束",
        0: "办理中",
      },
      chongzhanflag: _chongzhanflag,
      showAddModal: false, //添加
      showDetail: false, // 查看详情
      multipleSelection: [], // 选中的表格项
      dynamicValidateForm: {}, // 表单
			remarkModel: false, //备注
      rules: {
        companyorgid: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        picurl: [{ trigger: "blur", message: "必填字段", required: true }],
        isflag: [{ trigger: "blur", message: "必填字段", required: true }],
        remark: [{ trigger: "blur", message: "必填字段", required: true }],
        transactiontype: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        schoolorgid: [{ trigger: "blur", message: "必填字段", required: true }],
        studentname: [{ trigger: "blur", message: "必填字段", required: true }],
        grade: [{ trigger: "blur", message: "必填字段", required: true }],
        levelkey: [{ trigger: "blur", message: "必填字段", required: true }],
        studytypekey: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        collegename: [{ trigger: "blur", message: "必填字段", required: true }],
        majorname: [{ trigger: "blur", message: "必填字段", required: true }],
        keleival: [{ trigger: "blur", message: "必填字段", required: true }],
        xuezhikey: [{ trigger: "blur", message: "必填字段", required: true }],

        classaddressid: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        guakaoschoolorgid: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        projecttype: [{ trigger: "blur", message: "必填字段", required: true }],
      }, //表单规则
      dialogTitle: "", //对话框标题
      fds: new FormData(),

      grade: [],
      leveltype: [],
      xuezhi: [],
      studytypeval: [], // 学习形式
      collegecoporgList:[],//上课地点

      projectTypes: _projectTypes,
      changeTypes: _changeTypes,
      changeType: _changeType,
      showDetailModal: false,
      tabId: null,
      allDetailForm: {},
      vstudentpotential: {},
      showTransactionModal: false,
      showApprove2Modal: false,

      selectTable: {},
      selectPageInfo: {
        pageindex: 1,
        pagesize: 10,
      },
      selectTableUrl: null,
      selectSchoolorgid: null,
      showDeliModal: false,
      showCollegeModal: false,
      showAcademyModal: false,
      selectSearchForm: {},
      showTransacListModal: false,
      showTransacEditModal: false,
			educationList: [],
    };
  },
  created() {
    this.getDataList();
    this.dic_combox({
      list: "grade",
      typecode: "grade",
      that: this,
    });
    this.dic_combox({
      list: "leveltype",
      typecode: "leveltype",
      that: this,
    });
    this.dic_combox({
      list: "xuezhi",
      typecode: "xuezhi",
      that: this,
    });
    this.dic_combox({
      list: "studytypeval",
      typecode: "studytype",
      that: this,
    });

		this.dic_combox({
      list: "educationList",
      typecode: "education",
      that: this,
    });
    this.org_combox();
    this.org_comboxchild(this.dynamicValidateForm.companyorgid);
		window.addEventListener("keyup", this.enterSelect);
  },
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    ...mapActions("selectData", ["dic_combox"]),
    ...mapActions("selectData", ["org_combox", "org_comboxchild"]),
    //改变分公司 change
    onChangeCompany(val, form) {
      this[form].schoolorgname = "";
      this[form].companyorgid = val;
      this.org_comboxchild(val);
    },
    onChangeSchool(val, form) {
      this[form].schoolorgid = val;
    },
		//改变函授站 change
		onGuakaoschoolorChange(val, form) {
			this[form].guakaoschoolorgid = val;
			this.dynamicValidateForm.guakaoschoolorgid = this[form].guakaoschoolorgid;
			this.collegecoporglist();
			this.dynamicValidateForm.classaddress = "";
		},
		//改变上课开班地点 change
		onClassaddressChange(val, form) {
			this[form].classaddressid = val;
			this.dynamicValidateForm.classaddressid = this[form].classaddressid;
		},
    //加载上课地点 api
    collegecoporglist() {
      myRequest({
        url: studentregister_getclassname,
        data: {
					collegeid: this.dynamicValidateForm.collegeid,
					schoolorgid:this.dynamicValidateForm.guakaoschoolorgid,
          pagesize:20
        },
      }).then(res=>{
        if(res.data.code==='200'){
         this.collegecoporgList = res.data.data.list

        }
      })
    },
		expand(val) {
			this.photosrcList = []
			this.photosrcList.push(val)
		},
		vbs(val) {
			this.srcList = []
			this.srcList.push(val)
		},
		//隐藏和显示
		handleShow(){
			this.showDetailModal = false;
		},
		//改变切换栏
		toggleTab(current) {
			this.currentTab = current;
			this.currentClass = current;
		},
    //关闭导出框
    onCloseExport(bool) {
      this.transferDialog = false;
    },
    //导出 click
    btnExport() {
        confirmCallBack({
            title: "提示",
            content: "请根据搜索条件进行导出。",
            success: () => {
                window.location.href =
                    this.$url.Freemarker +
                    studentregister_export1 +
                    `?${createObjParams(this.searchForm)}`;
            },
            fail: () => {
                messageTip({
                    message: "取消操作",
                    type: "info",
                });
            },
        });
    },
    //关闭导入框
    onCloseImport(bool) {
      this.importDialog = bool;
    },
		enterSelect(e) {
			if(e.keyCode == 13) {
				this.getDataList();
			}
		},
		visibleType(e) {
			if(!e) {
				this.$refs.projecttyperef.blur();
			}
		},
		reset() {
			this.searchForm = {
				studentname: null,
				studentidcard: null,
				collegename: null,
				majorname: null,
				grade: null,
				levelval: null,
				followusername: null,
				schoolorgname: null,
				projecttype: null,
				remark: null
			}
		},
		//重置
		resetQuery() {
			this.reset();
			this.getDataList();
		},
		selectQuery() {
			this.selectSearchForm = {
				qianzainame: null,
				mobile: null,
				schoolorgname: null,
				collegename: null,
				name: null,
				majorlevelval: null
			}
		},
		handleQuery() {
			this.selectQuery();
			this.onSelectData(this.selectTableUrl, null, null);
		},
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: studentregister_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },

    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
		btnRemark() {
			selectCheck(this.multipleSelection, "添加备注", true, () => {
			  this.rulesForm = {
			  	remark: "",
					ids: this.multipleSelection.map((i) => i.id)
			  }
				this.remarkModel = true;
			});
		},
    // 查看全部详情路由页 api
   btnShowDetail(id) {
      this.tabId = id;
      myRequest({
        url: studentregister_show2,
        data: {
          id: this.tabId,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.allDetailForm = {};
          this.allDetailForm = res.data.data;
          this.vstudentpotential = this.allDetailForm.vstudentregister;
					this.vstudentpotential.studentname = this.allDetailForm.vstudentregister.studentname;
					this.vstudentpotential.photo =  this.allDetailForm.vstudentregister.photo;
					this.vstudentpotential.code = this.allDetailForm.vstudentregister.code;
					this.vstudentpotential.total = this.allDetailForm.total;
					this.vstudentpotential.list1 = this.allDetailForm.list1;
					this.vstudentpotential.list2 = this.allDetailForm.list2;
					this.vstudentpotential.list3 = this.allDetailForm.list3;
          this.showDetailModal = true;
        }
      });
    },

    //  查看详情 tab切换 change
    handleClick() {
      this.btnShowDetail();
    },
    //添加click
    btnAdd() {
      this.dialogTitle = "添加";
      this.dynamicValidateForm = {
        schoolorgid: this.$store.state.login.userinfo.schoolorgid,
        companyorgid: this.$store.state.login.userinfo.companyorgid,
      };
      this.$set(
        this.dynamicValidateForm,
        "companyorgname",
        this.$store.state.login.userinfo.companyorgname
      );
      this.$set(
        this.dynamicValidateForm,
        "schoolorgname",
        this.$store.state.login.userinfo.schoolorgname
      );
      this.org_comboxchild(this.dynamicValidateForm.companyorgid);

      this.showAddModal = true;
    },
    //修改click
    btnEdit() {
      this.dialogTitle = "修改";
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.editRoutePage(this.multipleSelection[0].id);
      });
    },
    //删除click
    btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //下载 click
    downloadFile(row) {
      location.href =
        this.$url.Freemarker + _filedownload_save + "?fileurl=" + row.picurl;
    },
    //下载导入模板 click
    btnTemplate2() {
      location.href =
        this.$url.Freemarker +
        studentregister_download2 +
        "?fileurl=" +
        "/download/06.zip";
    },
    //上传文件前 before
    beforeUpload(file) {
      this.fds.append("upfile", file); // 传文件
    },
    //上传文件 upload
    uploadFile() {
      myRequest({
        method: "post",
        url: _pictureupload_save,
        data: this.fds,
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm.picurl = res.data.url;
        }
      });
    },
    //办理结束 click
    btnSuccess() {
      selectCheck(this.multipleSelection, "办理结束", false, () => {
        this.dialogTitle = "办理结束";
        this.approve2();
      });
    },
    //办理结束路由页 api
    approve2() {
      myRequest({
        url: transaction_approve2,
        data: {
          id: this.multipleSelection[0].id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showApprove2Modal = true;
          this.dynamicValidateForm = res.data.data.vtransaction;
          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
          delete this.dynamicValidateForm.deleteflag; //deleteflag 该字段不用传
        }
      });
    },
		//备注
		submitRemark(rulesForm) {
		  this.$refs[rulesForm].validate((valid) => {
		    if (valid) {
		      myRequest(
		        {
							method: "post",
		          url: studentregister_updateremark,
							data: this.$qs.stringify(this.rulesForm, {
								arrayFormat: 'repeat'
							}),
		        },
		        {
		          that: this,
							isRefTableList: true,
		          modal: "remarkModel",

		        }
		      );
		    }
		  });
		},
    //保存办理结束 api
    saveApprove2(dynamicValidateForm) {
      this.$refs[dynamicValidateForm].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: transaction_managesuccess,
              data: this.dynamicValidateForm,
            },
            {
              that: this,
              modal: "showApprove2Modal",
              methodName: "transactionlist",
            }
          );
        }
      });
    },
    //申请异动 click
    btnApply() {
      this.dialogTitle = "申请异动";
      selectCheck(this.multipleSelection, "申请异动", false, () => {
        this.transaction(this.multipleSelection[0].id);
      });
    },
    //查看异动 click
    btnShowTransaction() {
      selectCheck(this.multipleSelection, "查看异动", false, () => {
        this.rowItem = this.multipleSelection[0];
        this.dialogTitle = "查看异动";
        this.transactionlist();
      });
    },
    //导入学籍 click
    btnImport1() {
      this.importSaveUrl = studentregister_importsave2;
      this.downloadUrl = "download/教学管理/学历/学籍导入模板.xls";
      this.importDialog = true;
    },
    //导入学/考 click
    btnImport2() {
      this.importSaveUrl = studentregister_importcodesave;
      this.downloadUrl = "download/教学管理/学历/导入学号考生号模板.xls";
      this.importDialog = true;
    },
    //导入毕业状态 click
    btnImport3() {
      this.importSaveUrl = studentregister_importxuejistatussave;
      this.downloadUrl = "download/教学管理/学历/导入毕业状态模板.xls";
      this.importDialog = true;
    },
    //导入合作机构学籍 click
    btnImport4() {
      this.importSaveUrl = studentregister_importorgsave;
      this.downloadUrl = "download/教学管理/学历/导入合作机构学籍模板.xls";
      this.importDialog = true;
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },
    //确认德立学生 click
    onConfirmDeli() {
      selectCheck(this.multipleSelection, "选择", false, () => {
        this.dynamicValidateForm.studentid = this.multipleSelection[0].id;
        this.dynamicValidateForm.qianzaiid =
          this.multipleSelection[0].qianzaiid;
        this.$set(
          this.dynamicValidateForm,
          "studentname",
          this.multipleSelection[0].qianzainame
        );
        this.showDeliModal = false;
				this.selectQuery();
				this.multipleSelection = [];
      });
    },
    //确认专业 click
    onConfirmCollegemajor() {
      selectCheck(this.multipleSelection, "选择", false, () => {
        this.$set(
          this.dynamicValidateForm,
          "majorname",
          this.multipleSelection[0].name
        );
        this.$set(
          this.dynamicValidateForm,
          "collegename",
          this.multipleSelection[0].collegename
        );
        this.$set(
          this.dynamicValidateForm,
          "keleival",
          this.multipleSelection[0].majortypeval
        );
				this.dynamicValidateForm.collegeid =
				  this.multipleSelection[0].collegeid;
        this.dynamicValidateForm.majorid = this.multipleSelection[0].id;
        this.dynamicValidateForm.keleikey =
        this.multipleSelection[0].majortypekey;
        this.showCollegeModal = false;
				this.selectQuery();
				this.multipleSelection = [];
      });
    },
    //确认院校 click
    onConfirmAcademy() {
      selectCheck(this.multipleSelection, "选择", false, () => {
        this.$set(
          this.dynamicValidateForm,
          "majorname2",
          this.multipleSelection[0].name
        );
        this.$set(
          this.dynamicValidateForm,
          "collegename2",
          this.multipleSelection[0].collegename
        );
        this.dynamicValidateForm.majorid2 = this.multipleSelection[0].id;
        this.dynamicValidateForm.collegeid2 =
          this.multipleSelection[0].collegeid;
				this.collegemajor_getcollegemajor();
				this.showAcademyModal = false;
				this.selectQuery();
				this.multipleSelection = [];
      });
    },

    //查看异动的修改 click
    btnEditDetial() {
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.editDetailInput(this.multipleSelection[0].id);
      });
    },
    //查看异动的删除 click
    btnDelDetial() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delDetial(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //查看异动的修改路由页 api
    editDetailInput(id) {
      myRequest({
        url: transaction_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showTransacEditModal = true;
          this.dynamicValidateForm = res.data.data.vtransaction;
          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
        }
      });
    },

    //查看异动的修改下的异动类型选项 api
    changeTransaction(val) {
      this.dynamicValidateForm.transactiontype = val;
    },

    //查看异动的删除 api
    delDetial(ids) {
      myRequest({
        method: "post",
        url: transaction_delete,
        data: this.$qs.stringify(
          {
            ids: ids,
          },
          {
            arrayFormat: "repeat",
          }
        ),
      }).then((res) => {
        if (res.data.code === "200") {
          this.transactionlist();
        }
      });
    },

    //查看异动的保存  api
    saveDetail(dynamicValidateForm) {
      this.$refs[dynamicValidateForm].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: transaction_save,
              data: this.dynamicValidateForm,
            },
            {
              that: this,
              modal: "showTransacEditModal",
              methodName: "transactionlist",
            }
          );
        }
      });
    },

    //修改路由页 api
    editRoutePage(id) {
      myRequest({
        url: studentregister_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showAddModal = true;
					this.collegemajor_getcollegemajor();
					this.dynamicValidateForm.classaddress = "";
					this.dynamicValidateForm.schoolorgname2 = "";
          this.dynamicValidateForm = res.data.data.vstudentregister;
          this.org_comboxchild(this.dynamicValidateForm.companyorgid);
          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
          delete this.dynamicValidateForm.isoktime; //isoktime 该字段不用传
        }
      });
    },
    //提交-（添加，修改） api
    submitAddOrEditForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: studentregister_save2,
              data: this.dynamicValidateForm,
            },
            {
              isRefTableList: true,
              that: this,

              modal: "showAddModal",
            }
          );
        }
      });
    },
    //申请异动路由页 api
    transaction(id) {
      myRequest({
        url: transaction_input2,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showTransactionModal = true;
					this.collegemajor_getcollegemajor();
          this.dynamicValidateForm = Object.assign(
            res.data.data.vstudentregister,
            {
              picurl: null,
            }
          );

          this.dynamicValidateForm.studentregisterid =
            this.dynamicValidateForm.id;
          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
          delete this.dynamicValidateForm.id;
        }
      });
    },
    //申请异动保存 api
    transactionsave(dynamicValidateForm) {
      this.$refs[dynamicValidateForm].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: transaction_save,
              data: this.dynamicValidateForm,
            },
            {
              isRefTableList: true,
              that: this,
              modal: "showTransactionModal",
            }
          );
        }
      });
    },
    //查看异动 api
    transactionlist() {
      myRequest({
        url: transaction_list2,
        data: {
          pageindex: this.pageinfo.pageindex,
          pagesize: this.pageinfo.pagesize,
          studentregisterid: this.rowItem.id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.selectTable = res.data.data;
          this.showTransacListModal = true;
        }
      });
    },
    //删除 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: studentregister_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },

    //切换选项表格页
    handleSelectChange(val, data, prop, page) {
      this.selectPageInfo.pageindex = val;
      this.onSelectData(this.selectTableUrl, null, data);
			this.onCloseSelect();
    },

    //选中表格数据改变时 change
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    //选项表格 api（专业，德立学生,院校）
    onSelectData(url, modal, data) {
      this.selectTableUrl = url;
      myRequest({
        url: this.selectTableUrl,
        data: Object.assign(data || {}, this.selectPageInfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.selectTable = res.data.data;
					this.key = Math.random();
          this[modal] = true;
        }
      });
    },
    //选项表格 - 搜索 click
    onSelectSearchClick(url, modal, data) {
      this.selectPageInfo.pageindex = 1;
      this.onSelectData(url, modal, data);
    },
    //关闭选项框 click
    onCloseSelect() {
      this.selectPageInfo.pageindex = 1;
    },

   //函授站 api
    collegemajor_getcollegemajor() {
     myRequest({
				url:collegemajor_getcollegemajor,
        data:{
					id: this.multipleSelection[0].collegeid
        }
      }).then((res) => {
        if (res.data.code === "200") {
					this.map = res.data.data.map
					for(const key in this.map){
						// console.log(key,'key')
						// console.log('value',this.map[key])
					}
        }

      });
    },
    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>


<style lang="scss" scoped>
	@import "../../../assets/css/pulid.scss";
</style>
<style>
	.table_colim {
		border-bottom: 1px solid #EBEEF5;
	}
</style>

